export default class HeaderMenu {
  constructor($ele) {
    this.$body = document.body;
    this.$pageHeader = $ele;
    this.$menuBar = $ele.querySelector('#menuTrigger');
    this.$mainMenu = $ele.querySelector('navigation');
    this.$nav_list = $ele.querySelectorAll('.nav-items');
    this.$nav_items = $ele.querySelectorAll('.nav-items li');
    this.$navArrow = $ele.querySelectorAll('.navigation .has-sub .nav-arrow , .nav-item.has-sub > a');
    this.menuTrigger();
    this.menuOpen();
    this.onResize();
  }

  onResize() {
    window.addEventListener('resize', () => {
      if (window.innerWidth > 993) {
        this.$body.classList.remove('menu--open');
      }
    });
  }


  // Menu Trigger
  menuTrigger() {
    this.$menuBar.addEventListener('click', () => {
      this.$body.classList.toggle('menu--open');
      for (let i = 0; i < this.$nav_items.length; i += 1) {
        this.$nav_items[i].classList.remove('slide--up');
      }
    });
  }

  // menu Open Mobile devices
  menuOpen() {
    this.$navArrow.forEach(($e) => {
      $e.addEventListener('click', (event) => {
        event.preventDefault();
        this.removeChildFromSiblings($e.parentNode);
        $e.parentNode.classList.toggle('active');
        $e.parentNode.classList.toggle('slide--up');
      });
    });
  }

  removeChildFromSiblings(elem) {
    const siblings = [];

    if (!elem.parentNode) {
      return siblings;
    }

    let sibling = elem.parentNode.firstChild;

    while (sibling) {
      if (sibling.nodeType === 1 && sibling !== elem) {
        sibling.classList.remove('active');
        sibling.classList.remove('slide--up');
      }
      sibling = sibling.nextElementSibling;
    }
    return siblings;
  }
}

document.querySelectorAll('.page-header').forEach(($el) => new HeaderMenu($el));
if (!document.body.className.includes('ns-website-content')) {
  document.addEventListener('click', (event) => {
    if (event.target.href && !event.target.href.includes('tel:') && !event.target.href.includes('javascript:;') && !event.target.href.includes('mailto:') && event.target.tagName === 'A' && !event.target.hasAttribute('target') && !event.target.hasAttribute('data-fancybox') && !event.target.hasAttribute('data-bs-toggle', 'modal') && !event.target.hasAttribute('data-glightbox') && !event.target.hasAttribute('data-darkbox') && !event.target.className.includes('cboxElement') && !event.target.className.includes('lightbox') && !event.target.className.includes('has-sub-link')) {
      document.body.classList.remove('menu--open');
      const url = event.target.href;
      fetch(url, { cache: 'force-cache' })
        .then((response) => response.text())
        .then((html) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, 'text/html');
          const mainClass = doc.querySelector('.page-header').className;
          const mainHeader = doc.querySelector('.page-header').innerHTML;
          setTimeout(() => {
            document.querySelector('.page-header').className = mainClass;
            document.querySelector('.page-header').innerHTML = mainHeader;
          }, 100);
        })  
        .catch((err) => {
          console.warn(err, 'this.options.errorMsg, err');
        });
    }
  });
}

// Header sticky functionality
const header = document.querySelector('.page-header');

window.addEventListener('scroll', () => {
  header.classList.toggle('header-sticky', window.scrollY > 50);
}, false);
