// // using ES6 modules
import VanillaSiteSpeedBooster from '@nitsantechnologies/vanilla-site-speed-booster';

// using CommonJS modules
const optitasBooster = new VanillaSiteSpeedBooster({
  enableProgressBar: true,
  idBundleJs: 'pageAjax',
  errorMsg: 'Oops! Fatal error in VanillaSiteSpeedBooster plugin',
  mainClassName: '.site-main',
  pageBackForwardReload: true,
  removeUsingTargetClass: ['lang-menu-item'],
  removeWithoutReloadUsingTargetClass: ['listing-sidebar__link', 'nav-link'],
});
